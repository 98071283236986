import React, { useEffect, useRef } from "react";
import "./SlotReview.scss";
import avatar from "../../assets/avatar.webp";
import img1 from "../../assets/reviewImg1.webp";
import img2 from "../../assets/features.webp";
import img3 from "../../assets/features2.webp";

const SlotReview = () => {
  return (
    <div className="slot-review center" id={2}>
      <h2>Slot Review</h2>
      <div className="slot-review-user">
        <img src={avatar} alt="avatar" />
        <div className="slot-review-user-comment">
          <div>
            <p>Written by: James J. Hetrick, Senior Content Editor</p>
            <p>August 31, 2021 at 1:03PM</p>
          </div>
          <p>Last Updated: December 8, 2023 at 9:16PM (Michael Gordon)</p>
        </div>
      </div>

      <div className="slot-review-content" >
        <p>
          Mental is one of those slots that remain in history, and this time
          it’s because of the unique story and the impact it made on the entire
          slot industry. Dark and brutally honest, it doesn’t shy away from
          showing gruesome images of barbaric medical practices used by mental
          hospitals. So, this one is definitely not for the faint-hearted.{" "}
        </p>
        <img src={img1} alt="img" />
        <p>
          Before the release of Mental, the slot industry was a place of fairy
          tales, fruits, cute characters and all sorts of gods. But the success
          of this Nolimit City’s masterpiece convinced the industry that there’s
          a high demand for darker themes. So, other studios started to follow
          Nolimit’s footsteps sprinkling a bit of raw horror spice on their
          otherwise sweet and shiny portfolios.{" "}
        </p>
        <p>
          The slot went on to be an incredibly popular one, landing 13th in our
          Best New Slots of 2021 and 26th in our Top Slots of 2022 ratings. Of
          course, the intrigue of this unconventional slot theme must have
          played a big role, but it wasn’t without the help of an amazing
          gameplay. Mental is packed with an insane number of features and bonus
          rounds. Throw the 66,666x max win into the mix, and you get multiple
          reasons to love this slot.{" "}
        </p>
      </div>

      <div className="slot-review-features">
        <h2>Features</h2>
        <img src={img2} alt="features" />
        <p>
          For quite some time Nolimit City was known for its Extreme Volatility
          level. But then it boosted the values even higher introducing Insane
          Volatility, and Mental is one of the first of their insanely volatile
          games. The slot doesn’t introduce any new features, but it’s one of
          the first to have so many of them at the same time. Here are boosters
          and features it offers:{" "}
        </p>
        <ul>
          <li>
            xWays, xSplit, and xNudge. Before the release of this game, there
            weren’t any slots that featured not one or two but three xMechanics
            at the same time.{" "}
          </li>
          <li>
            Enhancer Cells are also something that fans had already seen in the
            studio’s previous releases. This time they are activated by the Fire
            Frames and reveal high value symbols, wilds or feature symbols.{" "}
          </li>
          <li>
            Up to x9,999 multiplier when 2+ Dead Patient symbols land in view.
            This massive multiplier adds another level of insanity to this slot,
            but to get the high values, you’ll need a lot of those symbols.{" "}
          </li>
          <li>
            Transformation. Spiders landing on reel 2 and 4 transform into high
            value or special symbols.
          </li>
        </ul>
        <p>
          You can have fun by jumping right into testing all those crazy
          features the studio mixed together. Play free Mental slot right on
          this page. And it’s very convenient to have multiple bonus buy
          options, because you can enter all of them instantly and trigger all
          available boosters.
        </p>
        <img src={img3} alt="features2" />
        <h2>Bonus Games </h2>
        <p>
          There are three bonus games you can enter. All three have different
          names, but you can basically say that they are just three levels of
          the same free spins round. You’ll get more spins and an extended set
          of boosters the more bonus symbols landed.{" "}
        </p>
        <ul>
          <li>
            3 Scorpions award 8 free spins with Fire Frames that do not reset
            between spins.{" "}
          </li>
          <li>
            3 Scorpions + 1 Spider unlock 9 free spins. Just like Fire Frames,
            Spiders become sticky and every round they trigger the Mental
            Transform feature. Plus, Dead Multipliers for each Patient are
            collected and then applied to their respective Patient symbols.{" "}
          </li>
          <li>
            3 Scorpions + 2 Spiders trigger the most promising version of the
            bonus. 10 free spins with all the features from the earlier levels,
            plus both Spiders sticky and triggering Mental Transform in every
            round. Dead Multipliers count doesn’t go down as in the previous
            bonus round and can only go up.{" "}
          </li>
        </ul>

        <h2>Conclusion </h2>
        <p>
          Mental lives up to its name. It tells a disturbing story set against
          the gruesome backdrop of a mental hospital where patients are ‘taken
          care of’ and features an insane pack of features. This release made a
          big impact on the landscape of the slot industry, and that makes it
          even more significant.{" "}
        </p>
      </div>
    </div>
  );
};

export default SlotReview;
