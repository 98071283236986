import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import SideBar from "./components/sideBar/SideBar";
import { useEffect, useRef, useState } from "react";
import GameRating from "./components/gameRating/GameRating";
import BasicInfo from "./components/basicInfo/BasicInfo";
import StickyBar from "./components/stickyBar/StickyBar";
import SlotReview from "./components/slotReview/SlotReview";
import SlotItems from "./components/slotItems/SlotItems";
import Slider from "./components/slider/Slider";
import Faq from "./components/faq/Faq";

function App() {
  const [open, setOpen] = useState(true);
  const target = [
    { title: "Basic Information", id: "1" },
    { title: "Mental Slot Review", id: "2" },
    { title: "Symbols and Paytable", id: "3" },
    { title: "Screenshots", id: "4" },
    { title: "Slot FAQs", id: "5" },
  ];
  return (
    <>
      <Header setOpen={setOpen} />
      <div className="app">
        <SideBar open={open} />
        <GameRating />

        <section className="center">
          <div>
            <BasicInfo />
            <SlotReview />
            <SlotItems />
            <Slider />
            <Faq />
          </div>

          <StickyBar target={target} />
        </section>
      </div>
      <Footer />
    </>
  );
}

export default App;
