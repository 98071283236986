import React from "react";
import "./Header.scss";
import logo from "../../assets/logo.webp";

const Header = ({ setOpen }) => {
  const isOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <header>
      <div className="header-container">
        <div className="header-container-logo">
          <div className="header-container-burger">
            <button onClick={isOpen}>
              <i
                className="fa-solid fa-bars fa-xl"
                style={{ color: "white" }}
              ></i>
            </button>
          </div>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="header-container-button">
          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button>Play Now</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
