import React, { useState } from "react";
import "./Faq.scss";

const Faq = () => {
  const Collapsible = ({ title, children }) => {
    const [isActive, setIsActive] = useState(true);

    const handleToggle = () => {
      setIsActive(!isActive);
    };

    return (
      <div className="coll" id="5">
        <div>
          <button
            className={`collapsible ${isActive ? "active" : ""}`}
            onClick={handleToggle}
          >
            <div className="plus">
              {isActive ? (
                <i class="fa-solid fa-angle-up"></i>
              ) : (
                <i class="fa-solid fa-angle-down"></i>
              )}
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
          </button>
        </div>
        {isActive && (
          <div className="content">
            <p>{children}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="faq">
      <h2>Mental Slot FAQs</h2>
      <Collapsible title="Can I play Mental free slot?">
        Yes, clashofslots.com is the place to try Mental with no sign up
        required. Any gambling site partnering with NoLimit City would also
        provide free access to the test mode.
      </Collapsible>
      <Collapsible title="Can I win real money on Mental slot?">
        Yes, registered account with a gambling site would be the only option to
        play real money Mental and land real winnings.
      </Collapsible>
      <Collapsible title="What is the best place to play Mental?">
        When it comes to free play any trusted gambling operator or casino
        related site such as Clash of Slots would be fine. Once real cash gets
        involved licensed operator with solid reputation and top tier services
        must be selected. Best casino would vary depending on one`s personal
        preferences.
      </Collapsible>
      <Collapsible title="Can I play Mental slot on mobile phone?">
        Yes, this video slot is mobile optimized and can be played on any
        device.
      </Collapsible>
      <Collapsible title="How to win playing Mental?">
        This is a certified video slot that generates random outcomes, and all
        you need is a good luck. Look through the paytable to learn how and how
        much you can win.
      </Collapsible>
    </div>
  );
};

export default Faq;
