import React from "react";
import "./SlotItems.scss";
import item1 from "../../assets/grid1.webp";
import item2 from "../../assets/grid2.webp";
import item3 from "../../assets/grid3.webp";
import item4 from "../../assets/grid4.webp";
import item5 from "../../assets/grid5.webp";
import item6 from "../../assets/grid6.webp";
import item7 from "../../assets/grid7.webp";
import item8 from "../../assets/grid8.webp";
import item9 from "../../assets/grid9.webp";
import item10 from "../../assets/grid10.webp";
import item11 from "../../assets/grid11.webp";
import item12 from "../../assets/grid12.webp";
import item13 from "../../assets/grid13.webp";
import item14 from "../../assets/grid14.webp";

const SlotItems = () => {
  const cards = [
    {
      id: 1,
      img: item1,
      text1: "5",
      text2: "x5",
      text3: "4",
      text4: "x1.5",
      text5: "3",
      text6: "x0.75",
    },
    {
      id: 2,
      img: item2,
      text1: "5",
      text2: "x3.75",
      text3: "4",
      text4: "x1.25",
      text5: "3",
      text6: "x0.6",
    },
    {
      id: 3,
      img: item3,
      text1: "5",
      text2: "x2.5",
      text3: "4",
      text4: "x1.1",
      text5: "3",
      text6: "x0.5",
    },
    {
      id: 4,
      img: item4,
      text1: "5",
      text2: "x2",
      text3: "4",
      text4: "x1",
      text5: "3",
      text6: "x0.45",
    },
    {
      id: 5,
      img: item5,
      text1: "5",
      text2: "x1.5",
      text3: "4",
      text4: "x0.9",
      text5: "3",
      text6: "x0.4",
    },
    {
      id: 6,
      img: item6,
      text1: "5",
      text2: "x1.25",
      text3: "4",
      text4: "x0.75",
      text5: "3",
      text6: "x0.3",
    },
    {
      id: 7,
      img: item7,
      text1: "5",
      text2: "x1.15",
      text3: "4",
      text4: "x0.65",
      text5: "3",
      text6: "x0.25",
    },
    {
      id: 8,
      img: item8,
      text1: "5",
      text2: "x1.1",
      text3: "4",
      text4: "x0.6",
      text5: "3",
      text6: "x0.2",
    },
    {
      id: 9,
      img: item9,
      text1: "5",
      text2: "x1.05",
      text3: "4",
      text4: "x0.55",
      text5: "3",
      text6: "x0.15",
    },
    {
      id: 10,
      img: item10,
      text1: "5",
      text2: "x1",
      text3: "4",
      text4: "x0.5",
      text5: "3",
      text6: "x0.1",
    },
  ];

  return (
    <div className="slot-items" id="3">
      <h2>Mental Symbols and Paytable</h2>
      <div className="slot-items-gird center">
        {cards.map((item) => (
          <div className="slot-items-gird-item" key={item.id}>
            <img src={item.img} alt="img" />
            <div className="slot-items-gird-item-text">
              <div>
                <p>{item.text1}</p>
                <p>{item.text2}</p>
              </div>
              <div>
                <p>{item.text3}</p>
                <p>{item.text4}</p>
              </div>
              <div>
                <p>{item.text5}</p>
                <p>{item.text6}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="slot-items-gird-item">
          <img src={item11} alt="img" />
          <div className="slot-items-gird-item-text">
            <div>
              <p className="np"></p>
              <p>Special</p>
            </div>
          </div>
        </div>
        <div className="slot-items-gird-item">
          <img src={item12} alt="img" />
          <div className="slot-items-gird-item-text">
            <div>
              <p className="np"></p>
              <p>Special</p>
            </div>
          </div>
        </div>
        <div className="slot-items-gird-item">
          <img src={item13} alt="img" />
          <div className="slot-items-gird-item-text ">
            <div>
              <p className="np"></p>
              <p>Special</p>
            </div>
          </div>
        </div>
        <div className="slot-items-gird-item">
          <img src={item14} alt="img" />
          <div className="slot-items-gird-item-text ">
            <div>
              <p className="np"></p>
              <p>Special</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotItems;
