import React from "react";
import "./GameRating.scss";
import img1 from "../../assets/img1.webp";
import screen from "../../assets/screen.png";

const GameRating = () => {
  return (
    <div className="game-rating">
      <div className="game-rating-container center">
        <div className="game-rating-container-img">
          <img src={img1} alt="img" />
        </div>
        <div className="game-rating-container-cont">
          <div className="game-rating-container-cont-text">
            <h1>Mental Slot Review</h1>

            <p>NOLIMIT CITY</p>
            <div>
              <p>9.3</p>
              <p>4 ratings</p>
              <div>
                <i class="fa-solid fa-star fa-2xs"></i>
                <p>Rate the Slot</p>
              </div>
            </div>
          </div>

          <div className="game-rating-container-cont-buttons">
            <div>
              <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
                <button>
                  Play for real{" "}
                  <i
                    class="fa-solid fa-arrow-right "
                    style={{ color: "white" }}
                  ></i>
                </button>
              </a>
              <p>18+ | Terms Apply | Play Responsibly</p>
            </div>
            <a href="https://colorful-road-three.com/d35db545f" target="_blank">
              <button>Play for fun</button>
            </a>
          </div>
          <div className="game-rating-container-cont-img">
            <div>
              <span></span>
              <div>
                <p>13</p>
                <p>Place</p>
              </div>
              <span className="even"></span>
              <p>Best New Slots 2021 </p>
            </div>
            <div>
              <span></span>
              <div>
                <p>26</p>
                <p>Place</p>
              </div>
              <span className="even"></span>
              <p>Most Popular Slots 2022 </p>
            </div>
            <div>
              <span></span>
              <div>
                <p>28</p>
                <p>Place</p>
              </div>
              <span className="even"></span>
              <p>Most Popular Slots 2023 </p>
            </div>
            {/* <img src={screen} alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameRating;
