import React from "react";
import "../basicInfo/BasicInfo.scss";
import { Link } from "react-scroll";

const StickyBar = ({ target }) => {
  return (
    <div className="basic-info-sticky">
      <div>
        {target.map((button, index) => (
          <Link to={button.id} spy={true} smooth={true} duration={500} key={index} offset={-80}>
            <button>{button.title}</button>
          </Link>
        ))}
        {/* <Link>
          <button>Mental Slot Review</button>
        </Link>
        <button>Slot Rating</button>
        <button>Symbols and Paytable</button>
        <button>Screenshots</button>
        <button>Slot FAQs</button>
        <button>Reviews by Players</button> */}
      </div>
    </div>
  );
};

export default StickyBar;
