import React, { useRef } from "react";
import "./BasicInfo.scss";

const BasicInfo = ({ id }) => {


  return (
    <div className="basic-info " id={1}>
      <div className="basic-info-table">
        <h2>Basic Information</h2>
        <div className="basic-info-table-row">
          <p>Software</p>
          <p>NoLimit City</p>
        </div>
        <div className="basic-info-table-row">
          <p>RTP</p>
          <p>97.02%</p>
        </div>
        <div className="basic-info-table-row">
          <p>Volatility</p>
          <p>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            High
          </p>
        </div>
        <div className="basic-info-table-row">
          <p>Max Win</p>
          <p>x66666</p>
        </div>
        <div className="basic-info-table-row">
          <p>Bet Range ($,€,£)</p>
          <p>0.20 - 70</p>
        </div>
        <div className="basic-info-table-row">
          <p>Number of Reels</p>
          <p>5</p>
        </div>
        <div className="basic-info-table-row">
          <p>Betways</p>
          <p>108</p>
        </div>
        <div className="basic-info-table-row">
          <p>Features</p>
          <p>
            Bonus Buy, Multiplier, Nudges, Split Symbols, Symbol Swap, xNudge,
            xWays
          </p>
        </div>
      </div>
      {/* <div className="basic-info-sticky">
        <div>
          <button>Basic Information</button>
          <button>Mental Slot Review</button>
          <button>Slot Rating</button>
          <button>Symbols and Paytable</button>
          <button>Screenshots</button>
          <button>Slot FAQs</button>
          <button>Reviews by Players</button>
        </div>
      </div> */}
    </div>
  );
};

export default BasicInfo;
