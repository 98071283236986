import React, { useEffect, useState } from "react";
import "./Footer.scss";

const Footer = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <footer>
      <div className="footer-container center">
        <div>
          <p>
            Experience the excitement of Mental Slots, where strategy and luck
            unite for an unparalleled gaming adventure.
          </p>
          <p>
            For any inquiries, please contact our support team at
            support@mentalslots.com
          </p>
        </div>

        <p>© {currentYear} Mental Slot. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
