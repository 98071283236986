import React from "react";
import "./Slider.scss";
import ImageGallery from "react-image-gallery";
import img1 from "../../assets/slider1.jpg";
import img2 from "../../assets/slider2.jpg";
import img3 from "../../assets/slider3.jpg";
import img4 from "../../assets/slider4.jpg";
import img5 from "../../assets/slider5.jpg";
import img6 from "../../assets/slider6.jpg";
import img7 from "../../assets/slider7.jpg";
import "react-image-gallery/styles/css/image-gallery.css";

const Sliderr = () => {
  const images = [
    {
      original: img1,
      thumbnail: img1,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img2,
      thumbnail: img2,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img3,
      thumbnail: img3,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img4,
      thumbnail: img4,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img5,
      thumbnail: img5,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img6,
      thumbnail: img6,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    {
      original: img7,
      thumbnail: img7,
      thumbnailWidth: 120,
      thumbnailHeight: 60,
      originalHeight: 460,
      originalWidth: 825,
      bulletClass: "tumbnailll",
    },
    // Add more images as needed
  ];


  return (
    <div className="slider" id="4">
      <h2>Screenshots</h2>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        thumbnailWidth={30}
      />
    </div>
  );
};

export default Sliderr;
