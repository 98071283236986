import React, { useState } from "react";
import "./SideBar.scss";
import vavada from "../../assets/vavada-com.webp";
import cat from "../../assets/cat.png";
import daddy from "../../assets/daddy.png";
import pinup from "../../assets/pinup.png";
import izzi from "../../assets/izzi.ico";
import gamma from "../../assets/gamm.png";

const SideBar = ({ open }) => {
  const images = [
    {
      id: 1,
      link: "https://vavadapartners.pro/?promo=9e1e1391-392b-4e33-87c7-a3523df5e8cf&target=register",
      alt: vavada,
      title: "Vavada Casino",
    },
    {
      id: 2,
      link: "https://catchthecatkz.com/d467ed7a5",
      alt: cat,
      title: "Cat Casino",
    },
    {
      id: 3,
      link: "https://onlinepuonline.com/cwbBKAX7/",
      alt: pinup,
      title: "Pin-Up Casino",
    },
    {
      id: 4,
      link: "https://izzicasino.life/cf91e09c3",
      alt: izzi,
      title: "Izzi Casino",
    },
    {
      id: 5,
      link: "https://nice-road-two.com/d74076b1c",
      alt: daddy,
      title: "Daddy Casino",
    },
    {
      id: 6,
      link: "https://colorful-road-three.com/d35db545f",
      alt: gamma,
      title: "Gamma Casino",
    },
  ];

  return (
    <aside>
      <div className="side-container">
        <div
          className="side-container-casino"
          style={open ? { display: "none" } : { display: "block" }}
        >
          <p>BEST ONLINE CASINOS</p>
          <div className="side-container-casino-items">
            {images.map((item) => (
              <a href={item.link} target="_blank">
                <div key={item.id}>
                  <img src={item.alt} alt={item.alt} />
                  <p>{item.title}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
